.categoryCheckbox .form-check-input {
    border-color: #953337;
}

.categoryCheckbox .form-check-input:checked {
    background-color: #953337;
}

.sortRadio {
    accent-color: #953337;
}
