.bgContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #A00000;
    height: 110px
}

.bgContainer::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background-image: url(../../img/bgImage.jpeg); */
    background-size: cover;
    background-position: center;
    opacity: 0.10
}

.infoTextContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    margin-bottom: 15px;
}

.searchContainer {
    position: relative;
    background: #f6f2ec;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60%;
    height: 65px;
    border-radius: 10px
}

.searchInputGroup {
    height: 75%;
}

.searchInput {
    font-weight: 400;
    border: 0;
    background-color: #f6f2ec
}

.searchInput:focus {
    background-color: #f6f2ec

}

.searchButton {
    width: 70px;
    background-color: #A00000;
    border: 0;
}

.searchButton:hover {
    background-color: #A00000;
}

.searchButton:active {
    background-color: #A00000;
}

.searchButton:focus {
    background-color: #A00000;
}

.searchButton:active:focus {
    background-color: #bc0000;
}

.searchIcon {
    font-size: 150%;
}

@media screen and (max-width: 768px) {
    .bgContainer {
        height: 63px;
    }

    .infoTextContainer {
        display: none;
    }

    .searchContainer {
        width: 100%;
        height: 45px;
        border-radius: 5px;
        padding: 0 5px;
    }

    .searchButton {
        width: 40px;
    }

    .searchIcon {
        font-size: 100%;
    }

    .searchInput {
        font-size: 16px;
    }

    .searchInputGroup {
        height: 35px;
    }
}