.header {
    background-color: #A00000;
    color: white;
    margin: 0;
    display: flex;
    padding: 1% 5%;
}

.headerTitle {
    font-size: 2.4vmax;
    font-weight: bold;
    margin: 0;
}

.criteriaContainer {
    margin: 0;
    padding: 1% 5%;
}

.appbarContainer {
    padding: 0;
    margin: 0;
}

.aboutUsContainer {
    margin: 1em auto;
}

.rateHeaderContainer {
    margin: 1em auto;
}

.rateSign {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
}

.rateName {
    display: flex;
    justify-content: start;
    align-items: center;
}

.rateNameText {
    margin: 0;
    font-weight: bold;
    font-size: 15pt;
}

.rateHeaderContainer {
    margin: 1em auto;
}

.appbarContainer {
    top: 0;
    padding: 0;
    margin: 0;
}

@media screen and (max-width: 992px) {
    .appbarContainer {
        position: sticky;
        top: 0;
        padding: 0;
        margin: 0;
    }

    .header {
        padding: 3% 5%;
    }

    .aboutUsContainer {
        margin-top: 0;
        margin-bottom: 0;
        padding: 2em;
        /* background-color: #f6f2ec; */
    }
}