.infoBarContainer {
  display: flex;
  justify-content: center;
  padding-left: 5px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 5px;
  margin: 0px;
  width: 100%;
}

.infoRow {
  width: 60%;
  margin: 0px;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  max-width: 100%;
}

.infoCol {
  padding-left: 5px;
  padding-right: 0px;
  padding-top: 0px;
  padding-bottom: 5px;
}

@media screen and (max-width: 992px) {
  .infoRow {
    width: 100%;
  }
  .infoBarContainer {
    padding : 3px 10px;
  }
  .infoCol {
    padding: 3px;
  }
}

.infoButton {
  width: 100%;
  height: 35px;
  background-color: #f6f2ec;
  cursor: pointer;
  color: #5A5858;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: solid #EBEBEB 1px;
  border-radius: 4px;
}

.infoButton:hover {
  background-color: rgb(235, 235, 235);
  color: #5A5858;
}

.infoButtonIcon {
  margin-right: 15px;
  margin-left: 10px;
  color: rgba(89, 108, 133, 1);
  font-weight: "bold";
  height: 100%;
  width: auto;
  padding: 0px;
}

.infoButtonText {
  color: #6B7280;
  width: 100%;
  height: 100%;
  text-align: left;
  display: flex;
  align-items: center;
}

.searchInput {
  width: 100%;
  background-color: #F6F2EC;
  border-width: 0px;
  border-radius: 0px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.searchFormControl {
  background-color: #F6F2EC;
  width: 90%;
  border-width: 0px;
  border-radius: 0px;
  color: #5A5858;
  outline: 'none'
}

.searchFormControl::placeholder {
  color: #C3C3C3;
  opacity: 1;
  outline: 'none';
  background-color: transparent;
}

.searchFormControl:hover {
  border-width: 0px;
  outline: 'none';
  box-shadow: none;
  background-color: transparent;
}

.searchFormControl:focus {
  border-width: 0px;
  outline: 'none';
  box-shadow: none;
  background-color: transparent;
}

.searchFormControl:active {
  border-width: 0px;
  outline: 'none';
  box-shadow: none;
  background-color: transparent;
}

.searchButton {
  background-color: #F6F2EC;
  border-width: 0px;
  border-radius: 0px;
  cursor: pointer;
  color: #5A5858;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.searchButton:hover {
  background-color: #efe4d3;
  color: #5A5858;
}

.searchButton:focus {
  background-color: #efe4d3;
  color: #5A5858;
}

.searchButton:active {
  background-color: #efe4d3;
  color: #5A5858;
}

.rateIcon {
  color: #C3C3C3;
  width: 15%;
  height: auto;
  max-height: 35px;
  max-width: 35px;
  padding-right: 3px;
  padding-left: 0px;
  padding-bottom: 0px;
  padding-top: 0px;
}

.rateContainer {
  align-items: center;
  justify-content: center;
  display: flex;
  height: 100%;
  padding: 0px;
  padding-bottom: 5px;
  margin: 0px;
  width: 100%;
}