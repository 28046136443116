.header {
    background-color: #A00000;
    color: white;
    margin: 0;
    display: flex;
    padding: 1% 5%;
}

.headerTitle {
    font-size: 2.4vmax;
    font-weight: bold;
    margin: 0;
}

.contentContainer {
    margin: 0;
    padding: 2% 5%;
}

.appbarContainer {
    top: 0;
    padding: 0;
    margin: 0;
}

@media screen and (max-width: 992px) {
    .appbarContainer {
        position: sticky;
        top: 0;
        padding: 0;
        margin: 0;
    }

    .header {
        padding: 3% 5%;
    }

    .contentContainer {
        margin-top: 0;
        margin-bottom: 0;
        padding: 3% 5%;
        /* background-color: #f6f2ec; */
    }


}