.brandListContainer {
    padding: 10px;
    margin: 0px;
    width: 60%;
}

@media screen and (max-width: 992px) {
    .brandListContainer {
        width: 100%;
    }
}