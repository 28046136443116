.sourceRow {
    border : 1px solid #586C85;
    border-radius: 5px; 
    margin: 8px;
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    background-color: #586C85;
    font-size: 10px;
    color: white;
}

.sourceItem {
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0;
    margin: 0;
    font-size: 6pt;
    width: 70px;
}

.parentBrandDiv {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    width: 50%;
    border: 1px solid #858A99;
    border-radius: 5px;
}

@media screen and (max-width: 992px) {
    .sourceItem {
        width: 60px;
    }

    .parentBrandDiv {
        width: 100%;
    }
}