.header {
    background-color: #A00000;
    color: white;
    margin: 0;
    display: flex;
    padding: 1% 5%;
}

.headerTitle {
    font-size: 2.4vmax;
    font-weight: bold;
    margin: 0;
}

.contactContainer {
    margin: 0;
    padding: 1% 5%;
}

.contactRow {
    display: flex;
}

.contactInfoNote {
    margin: 0;
    padding: 0;
    padding-left: 2px;
    padding-right: 40px;
    margin-bottom: 20px;
    font-size: 12pt;
}

.infoColumn {
    padding: 0;
    margin: 0;
    margin-top: 2rem;
}

.infoRow {
    margin: 0;
    padding: 0;
    flex-direction: row;
}

.formColumn {
    padding: 0;
    margin-left: 0;
    margin-right: 0;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.appbarContainer {
    top: 0;
    padding: 0;
    margin: 0;
}

@media screen and (max-width: 992px) {
    .appbarContainer {
        position: sticky;
        top: 0;
        padding: 0;
        margin: 0;
    }

    .header {
        padding: 3% 5%;
    }

    .contactContainer {
        margin-top: 0;
        margin-bottom: 0;
        padding: 1% 5%;
    }

    .contactRow {
        flex-direction: column;
    }

    .contactInfoNote {
        padding: 0;
    }
}
