.brand {
    height: auto;
    min-height: 80px;
    margin-bottom: 1.0rem;
    padding: 0.3rem;
    border-radius: 2px;
    background-color: #f6f2ec;
}

.brandLogoContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.brandLogo {
    height: 50px;
    max-width: 80px;
    object-fit: contain;
}

.brandName {
    height: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 15px;
    text-align: left;
    font-weight: bold;
    display: flex;
    align-items: center;
    color: #3f3f46;
}

.explanationContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
}

.explanation {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #858a99;
    font-size: 10pt;
}

.brandRow {
    margin-bottom: 0;
    margin-left: 1rem;
    margin-right: 1rem;
}

.rateContainer {
    margin: 0;
    padding: 0;
    display: flex;
}

.rateIcon {
    color: #C3C3C3;
    width: 25%;
    height: auto;
    max-height: 35px;
}

.rateContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 0;
}

.childBrandsScroll {
    display: flex;
    flex-direction: row;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-x: auto;
}

.childBrandsScroll::-webkit-scrollbar {
    display: none;
}

.showDetailsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    margin-top: 3px;
}

.showDetailsSpan {
    display: flex;
    justify-content: end;
    align-items: end;
    flex-direction: row;
    font-size: 10px;
    font-weight: 600;
    padding: 5px 10px;
    border-radius: 5px;
    margin-bottom: 3px;
}

@media screen and (max-width: 768px) {
    .explanationContainer {
        display: none;
    }

    .brandLogo {
        width: 70px
    }

    .brandRow {
        margin-bottom: 1.0rem;
    }

    .childBrandsScroll {
        margin-bottom: 0.5rem;
    }

    .brandRow {
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 0.5rem;
    }

    .showDetailsContainer {
        justify-content: flex-end;
    }

    .showDetailsSpan {
        font-size: 10px;
        font-weight: 600;
        padding: 6px 15px;
        border-radius: 5px;
        background-color: transparent;
        margin-right: 2px;
    }
}