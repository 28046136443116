.bgContainer {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #A00000;
    height: 10vh;
    margin-top: 8px;
    padding: 0 5%;
}

.bgContainer::before {
    content: normal;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(../../img/bgImage.jpeg);
    background-size: cover;
    background-position: center;
    opacity: 0.0
}

.footerLeft {
    display: flex;
    align-items: center;
    padding: 0;
}

.footerRight {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0;
}

.footerSocialMedia {
    display: none;
    justify-content: end;
    padding: 0;
}

.footerAllRightsReserved {
    display: flex; 
    justify-content: end;
    font-size: 10pt;
    color: white;
    padding: 0;
}

@media screen and (max-width: 768px) {
    .bgContainer {
        height: 70px;
    }
    .footerLeft {
        display: none;
    }
    .footerSocialMedia {
        display: flex;
        justify-content: center;
    }
    .footerAllRightsReserved {
        justify-content: center;
    }
}