.mainContainer {
    font-family: 'Poppins', sans-serif;
    margin: 0px;
    padding: 0px;
    min-height: 89.1vh;
}

.homeContainer {
    margin: 0px;
    padding: 0px;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
}

.categoryContainer {
    display: flex;
    justify-content: start;
    align-items: center;
    flex-wrap: wrap;
    width: 60%;
    padding: 0 10px;
    /* margin: 10px 0; */
}

.categoryItem {
    background-color: #586C85;
    color: #fff;
    width: 24%;
    padding: 0.5%;
    border-radius: 5px;
    margin-right: 1%;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}

.categoryText {
    width: 70%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.categoryCloseButton {
    cursor: pointer;
    font-size: 20px;
    margin-right: 7px;
}

@media screen and (max-width: 768px) {
    .categoryContainer {
        width: 100%;
    }
    .categoryItem {
        width: 32%;
        font-size: 10pt;
    }
    .categoryCloseButton {
        font-size: 13pt;
    }
    .appbarSearchInfo {
        background-color: #fff;
        position: sticky;
        top: 0;
        z-index: 2;
    }

    .mainContainer {
        min-height: 91.3vh;
    }
}